import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "hiddenInput"]

  static values = {
    numberOfDigits: Number
  }

  connect() {
    if (!this.hasHiddenInputTarget) { return }
    if (isNaN(this.numberOfDigitsValue)) { return }
    if (this.numberOfDigitsValue <= 0) { return }
    if (this.inputTargets.length != this.numberOfDigitsValue) { return }

    this.addEventListeners()
  }

  addEventListeners() {
    this.inputTargets.forEach((input, i) => {
      input.addEventListener("input", () => {
        // If the value to set is not a number, restore the previous value (if present).
        if (isNaN(input.value)) {
          input.value = input.dataset.otpInputRestore || ""
          this.updateInternalValue()
          return
        }

        // If a field is cleared, we save the empty value and are done.
        if (input.value.length == 0) {
          this.saveInputValue(i)
          return
        }

        // If a single character was entered, save the value and focus the next input (if present).
        if (input.value.length == 1) {
          this.saveInputValue(i)
          this.updateInternalValue()

          const nextIndex = i + 1
          if (nextIndex >= this.numberOfDigitsValue) { return }

          this.inputTargets[nextIndex].focus()
          return
        }

        // If multiple values were pasted but we have reached the last input field, we save this field and are done.
        if (i === this.numberOfDigitsValue - 1) {
          this.storeSingleCharacter(i, input.value)
          return
        }

        // If multiple values were pasted and we have not yet reached the last field, update this field and the following ones.
        const chars = input.value.split("")

        for (let position = 0; position < chars.length; position++) {
          const currentIndex = position + i
          // If we have reached the end of our input list, we're done.
          if (currentIndex >= this.numberOfDigitsValue) { break }

          // paste value and save
          this.storeSingleCharacter(currentIndex, chars[position])
        }

        // If there are fields left after we have finished entering/pasting data, focus the next field.

        // focus the input next to the last pasted character
        const focusIndex = Math.min(
          i + chars.length,
          this.numberOfDigitsValue - 1
        )

        this.inputTargets[focusIndex].focus()
      })

      input.addEventListener("keydown", (e) => {
        // Backspace
        // If the current input is NOT empty, store the new (empty) value.
        // If the current input is empty, however, we clear the previous one and focus it.
        if (e.keyCode == 8) {
          if (input.value !== "") {
            this.storeSingleCharacter(i, "")
            return
          } else if (input.value == "" && i > 0){
            this.storeSingleCharacter(i - 1, "")
            this.inputTargets[i - 1].focus()
            return
          }
        }

        // Delete button
        // If the current input is the last one, we do nothing special and just let the input handler store the new (empty) value.
        // If it is an earlier input, we clear it and move everything after it one to the left.
        if (e.keyCode == 46 && i !== (this.numberOfDigitsValue - 1)) {
          let selectionStart = input.selectionStart || 0

          for (let position = selectionStart + i; position < (this.numberOfDigitsValue - 1); position++) {
            this.storeSingleCharacter(position, this.inputTargets[position + 1].value)
          }

          this.storeSingleCharacter(this.numberOfDigitsValue - 1, "")

          // Restore the caret so the field is selected where the delte button was triggered on.
          if (input.selectionStart) { input.selectionStart = selectionStart }

          e.preventDefault()
          return
        }

        // left arrow
        if (e.keyCode == 37 && (input.selectionStart === null || input.selectionStart === 0)) {
          if (i <= 0) { return }

          e.preventDefault()
          const previousIndex = i - 1
          this.inputTargets[previousIndex].focus()
          this.inputTargets[previousIndex].select()

          return
        }

        // Right arrow
        if (e.keyCode == 39 && (input.selectionStart === null || input.selectionEnd === input.value.length)) {
          const nextIndex = i + 1
          if (nextIndex >= this.numberOfDigitsValue) { return }

          e.preventDefault()
          this.inputTargets[nextIndex].focus()
          this.inputTargets[nextIndex].select()

          return
        }
      })
    })
  }

  get internalValue() {
    return this.inputTargets
      .map(input => input.value)
      .join("")
  }

  updateInternalValue() {
    this.hiddenInputTarget.value = this.internalValue
  }

  saveInputValue(index, value) {
    const input = this.inputTargets[index]
    input.dataset.otpInputRestore = value || input.value
  }

  storeSingleCharacter(index, value) {
    const singleCharacter = String(value).substring(0, 1)
    if (isNaN(singleCharacter)) { return }
    // We musn't check for "empty value" here since we might want to explicitly store an empty value to clear a field (e.g. after delete-keying a not-last field).

    this.inputTargets[index].value = singleCharacter
    this.saveInputValue(index, singleCharacter)
    this.updateInternalValue()
  }
}
